import React from 'react';
import Check from '../images/badge-check.svg';

const embeddedTag = () => {
    return (
        <div className="badge-outer-main">
            <div className="badge-outer">
                <div className="badge-hpay">
                    <div className="badge-hpay-inner d-flex align-items-center">
                        <img src={Check} alt="check icon" />
                        <div className="badge-hpay-content">
                            <h5>AUDITED & KYCED</h5>
                            <div className="badge-hpay-line"></div>
                            <h6>by hedgepay</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default embeddedTag;